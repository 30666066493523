import './bootstrap';

import './modules/index';

(function (){
    $('.js-select').select2({
        theme: 'bootstrap4',
        width: 'resolve'
    });

    $('.dateformat').inputmask({alias: "datetime", inputFormat: "dd/mm/yyyy", placeholder: "dd/mm/yyyy" });
})();
