/**
 * /records url
 * historial module
 */
(function($){
    const dropdownItems = document.querySelectorAll('.dropdown-item'),
        formFilter = document.querySelector('.records-filters');

    dropdownItems.forEach(item => {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            const value = this.getAttribute('data-value'),
                dropdownToggle = document.getElementById('dropdownMenuLink'),
                pagination = document.getElementById('pagination');

            dropdownToggle.textContent = `Vista de ${value}`;
            pagination.value = value;
            formFilter.submit();
        });
    });

    if(document.querySelector('.wrap-order-by')) {
        const btnOrder = document.querySelector('.wrap-order-by');
        btnOrder.addEventListener('click', evt => {
            evt.preventDefault();
            let btn = evt.target;

            if(btn.localName === 'img') {
                btn = btn.closest('a');
            }

            if(btn.localName === 'div' && btn.classList.contains('disabled')) {
                return;
            }

            if(btn.classList.contains('disabled')) {
                console.log('asdsa');
                return;
            }

            document.querySelector('#order').value = btn.dataset.order;
            formFilter.submit();
        });
    }

    const btnListActivatoin = document.querySelectorAll('.records-codes__wrap-data a');

    if(btnListActivatoin.length) {
        Array.from(btnListActivatoin).map(btn => {
            btn.addEventListener('click', evt => {
                evt.preventDefault();
                let element = evt.target,
                    activationID = element.dataset.activation,
                    elementModal = document.querySelector('#modalActivation'),
                    modal = new bootstrap.Modal(elementModal),
                    container = element.closest('.records-codes__wrap-data');

                elementModal.querySelector('.modal-title').textContent = container.querySelector('.code-code p').textContent;
                elementModal.querySelector('.code-date').textContent = container.querySelector('.code-date p').textContent;
                elementModal.querySelector('.code-product').textContent = container.querySelector('.code-product p').textContent;
                elementModal.querySelector('.code-gamification').textContent = container.querySelector('.code-gamification p').textContent;
                elementModal.querySelector('.code-type').textContent = container.querySelector('.code-type p').textContent;
                modal.show();

            })
        });
    }

})(window.$);
