(function($){
    const form = document.querySelector('#perfil-show form');

    if(form){
        form.addEventListener('click', evt => {
            const target = evt.target;

            if(target.classList.contains('btn-change-pass'))
            {
                target.classList.add('d-none');
                const containers = document.querySelectorAll('.password-container');
                Array.from(containers).forEach(element => {
                    element.classList.remove('d-none')
                });

                document.querySelector('.forgot-pass').classList.remove('d-none');
                target.closest('.input-group').querySelector('input').value = '';

                if(form.querySelector('.button-actions').classList.contains('d-none')) {
                    form.querySelector('.button-actions').classList.remove('d-none');
                }
            } else if(target.classList.contains('btn-pass'))
            {
                let inputGroup = target.closest('.input-group'),
                input = inputGroup.querySelector('input');

                inputGroup.style.borderBottom = 'none';

                if(inputGroup.querySelector('button').classList.contains('hide-password')) {
                    inputGroup.querySelector('button').classList.remove('hide-password');
                    inputGroup.querySelector('button').classList.add('show-password');
                    input.type = 'text';
                } else {
                    inputGroup.querySelector('button').classList.add('hide-password');
                    inputGroup.querySelector('button').classList.remove('show-password');
                    input.type = 'password';
                }

            } else if(target.classList.contains('btn-primary') && document.querySelector('#password'))
            {
                if(document.querySelector('#current_password').value !== "" && document.querySelector('#password').value !== "" && document.querySelector('#password_confirmation').value !== ""){
                    evt.preventDefault();

                    Swal.fire({
                        title: '<p class="title-sweet">¡Importante!</p>',
                        imageUrl: `${window.baseURL}/frontend/assets/img/war-icon.png`,
                        html: '<span class="d-flex">¿Estás seguro de querer cambiar la contraseña de tu perfil?</span>',
                        confirmButtonText: 'Sí, cambiar',
                        showDenyButton: true,
                        denyButtonText: `<img src="${window.baseURL}/frontend/assets/img/left-arrow.svg" />&nbsp;&nbsp;Descartar`,
                        customClass: {
                            container: 'wrap-perfil-sweetalert',
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            form.submit();
                        }
                    });
                }
            } else if(target.classList.contains('btn'))
            {
                let inputGroup = target.closest('.input-group');
                let input = inputGroup.querySelector('input');

                input.removeAttribute('readonly');
                input.focus();
                target.classList.add('d-none');

                if(form.querySelector('.button-actions').classList.contains('d-none')) {
                    form.querySelector('.button-actions').classList.remove('d-none');
                }
            }
            return false;
        });

        const avatar = document.querySelector('#avatar'),
            wrapPerfilImage = document.querySelector('.perfil-image');
        // const previewImage = document.getElementById('previewImage');

        function previewSelectedImage() {
            const file = avatar.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function(e) {
                    previewImage.src = e.target.result;
                    previewImage.style.backgroundImage = e.target.result;
                }

                if(form.querySelector('.button-actions').classList.contains('d-none')) {
                    form.querySelector('.button-actions').classList.remove('d-none');
                }
            }
        }
        avatar.addEventListener('change', previewSelectedImage);

        const addImage = document.querySelector('.btn-add-image');
        addImage.addEventListener('click', evt => {
            let target = evt.target;

            if(target.localName === 'img') {
                avatar.click();
            }
        });
    }

    if($('#birth').length) {
        $('#birth').inputmask("99/99/9999",{
            jitMasking : true,
            placeholder : "dd/mm/yyyy",
            alias: "datetime",
        });
    }

    if($('#phones').length) {
        $('#phones').inputmask("9999-9999", {
            jitMasking : true,
            placeholder : "0000-0000",
        });
    }

})(window.$);
