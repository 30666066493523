(function($){

    //https://bootstrap-menu.com/detail-fixed-onscroll.html
    document.addEventListener("DOMContentLoaded", function(){
        const mainNav = document.getElementById('header');
        let navbar_height;
        window.addEventListener('scroll', function() {
            if (window.scrollY > 10) {
                mainNav.classList.add('fixed-top');
                // add padding top to show content behind navbar
                navbar_height = mainNav.offsetHeight;
                document.body.style.paddingTop = navbar_height + 'px';
            } else {
                mainNav.classList.remove('fixed-top');
                // remove padding top from body
                document.body.style.paddingTop = '0';
            }
        });
    });
})(window.$);
