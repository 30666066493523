$(document).ready(function() {
    const maxInputs = 10;
    let inputCount = 1;

    function createInput(removeButton = true) {
        return `<div class="input-container">
              ${removeButton ? '<button type="button" class="remove">-</button>' : ''}
              <input type="text" name="codes[]" placeholder="Código XXXXX">
              <button type="button" class="add">+</button>
              <div class="error-message"></div>
            </div>`;
    }

    $('#inputsContainer').on('click', '.add', function() {
        if (inputCount < maxInputs) {
            inputCount++;
            $('#inputsContainer').append(createInput());
            $('.message').text('');
        } else {
            $('.message').text('No puedes agregar más de 10 códigos.');
        }
        toggleAddButton();
    });

    $('#inputsContainer').on('click', '.remove', function() {
        $(this).parent('.input-container').remove();
        inputCount--;
        $('.message').text('');
        toggleAddButton();
    });

    function toggleAddButton() {
        $('.add').prop('disabled', inputCount >= maxInputs);
    }

    $('#inputsContainer').html(createInput(false));
    toggleAddButton();

    $('.close-popup').click(function() {
        $('.popup-background').hide();
        $('.popup').hide();
    });

    $('#codeForm').submit(function(event) {
        event.preventDefault();

        $.ajax({
            url: $(this).attr('action'),
            method: 'POST',
            data: $(this).serialize(),
            success: function(data) {
                $('#messages').html('');
                $('input[name="codes[]"]').removeClass('error success');
                $('.error-message').html('');

                if (data.status === 'error' || data.status === 'partial') {
                    data.errors.forEach(function(error) {
                        $('input[name="codes[]"]').each(function() {
                            if ($(this).val() === error.code) {
                                $(this).addClass('error');
                                $(this).parent('.input-container').css('margin-bottom', '35px');
                                $(this).siblings('.error-message').html(error.message);
                            }
                        });
                    });

                    $('#popup-icon').attr('src', `${window.baseURL}/frontend/assets/img/error-icon-popup.png`);
                    $('#popup-message').html('¡Oh no, ' + data.username + '! \n Algo salió mal.');
                    $('#popup-sub-message').html('Verifica los códigos y asegúrate de haber ingresado los datos correctamente. \n' +
                        '\n' +
                        'Revísalos y vuelve a intentarlo.');
                }

                if (data.status === 'success' || data.status === 'partial') {
                    data.successCodes.forEach(function(code) {
                        $('input[name="codes[]"]').each(function() {
                            if ($(this).val() === code) {
                                $(this).addClass('success');
                            }
                        });
                    });

                    $('#popup-icon').attr('src', `${window.baseURL}/frontend/assets/img/sucess-icon-popup.png`);
                    $('#popup-message').html('¡Felicidades, ' + data.username + '!');
                    $('#popup-sub-message').html('Has activado exitosamente tus códigos. ¡Estás en camino a ganar en grande!');
                }

                $('#messages').html('<div>' + data.message + '</div>');
                $('.popup-background').show();
                $('.popup').show();
            },
            error: function(error) {
                console.error('Error:', error);
            }
        });
    });
});
